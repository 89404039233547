body {
    margin: 0;
    padding: 0;
}

body * {
    font-family: Helvetica, sans-serif;
    font-size: 20px;
}

.container {
    width: 90%;
}

.hidden {
    display: none !important;
}

.text-center {
    text-align: center !important;
    margin: 0 auto;
}

.btn {
    background-color: #00f;
    color: #fff;
    text-decoration: none;
    padding: 0.5rem 0.25rem;
    margin: 0 auto;
    display: block;
    text-align: center;
    cursor: pointer;
    font-size: 1.5rem;
    line-height: 1.5rem;
}

.mt-1 {
    margin-top: 1rem !important;
}

.mb-1 {
    margin-bottom: 1rem !important;
}

.modal {
    width: 50%;
    top: 50px;
    left: 25%;
    padding: 0.5rem;
}

.modal h3 {
    font-size: 1.5rem;
    text-align: center;
    margin: 0;
}

.modal p {
    margin: 0;
    font-size: 1.25rem;
}

.modal input {
    width: calc(100% - 1rem);
    height: 2.5rem;
    font-size: 1.25rem;
    margin: 0.5rem 0;
}

.modal input[type=submit], .modal input[type=button] {
    border: 0px;
    width: 100%;
}

.error {
    text-align: center;
    background-color: #f8d7da;
    color: #721c24;
    border-color: #f5c6cb;
    padding: 0.5rem 0;
}


@media (max-width: 767px) {
    .modal {
        width: 90%;
        margin: 0 0.5rem;
        top: 50px;
        left: 0%;
        padding: 0.5rem;
    }

    table * {
        font-size: 1rem;
    }
}
