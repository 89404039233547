/*
  Animation styles from https://github.com/daneden/animate.css
*/

.animated {
    animation-duration: 1s;
    animation-fill-mode: both;
}

.animated.infinite {
    animation-iteration-count: infinite;
}

@keyframes slideInUp {
    from {
        transform: translate3d(0, 25%, 0);
        opacity: 0;
    }

    to {
        transform: translate3d(0, 0, 0);
        opacity: 1;
        visibility: visible;
    }
}

.slideInUp {
    animation-duration: 0.3s;
    animation-name: slideInUp;
}

@keyframes slideOutDown {
    from {
        transform: translate3d(0, 0, 0);
        opacity: 1;
    }

    to {
        visibility: hidden;
        opacity: 0;
        transform: translate3d(0, 25%, 0);
    }
}

.slideOutDown {
    animation-name: slideOutDown;
}

.char {
    width: 72px;
    height: 96px;
}

.cover {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 45%;
}

.field {
}

.hole {
    width: 72px;
    height: 47px;
    background-image: url('../images/hole.png');
}

.opacity-bg {
    background-color: #000;
    opacity: 0.70;
    display: inline-block;
    position: absolute;
    color: #fff;
    font-size: 1rem;
}

.opacity-bg a {
    color: #fff;
    cursor: pointer;
    display: block;
    width: 100%;
}

.meta {
    width: 135px;
    top: 0px;
    left: 0px;
}

.audio a {
    display: inline;
}

.picker {
    width: 75%;
    top: calc(25% - 1rem);
    left: 0;
    right: 0;
    margin: 0 auto;
    padding: 0.5rem;
    text-align: center;
    opacity: 1;
}

.picker .selected {
    background-color: #0f0;
}

.picker .selected a {
    color: #000;
}

.picker .char_container {
    width: 33%;
    display: inline-block;
    border: 1px solid #fff;
    margin: 0.5rem;
}

.picker .char_container a {
    display: block;
    width: 100%;
}

.picker .char {
    display: inline-block;
}

.picker p {
    margin: 0;
    text-transform: capitalize;
}

.picker .level_container {
    width: calc(33% - 1.5rem);
    display: inline-block;
    border: 1px solid #fff;
    padding: 0.5rem 0.25rem;
    margin: 0.25rem;
}

.picker .level_container.selected {
    background-color: #0f0;
}

.picker .btn {
    margin: 1rem;
}

.char .blurb {
    background-color: #fff;
    padding: 0.25rem;
    border-radius: 10px;
    font-weight: bold;
    position: relative;
    left: 12px;
    top: -12px;
    width: 100px;
}
